body {
  /* font-family: "Nunito", sans-serif; */
  font-family: "Noto Sans Mono", monospace;
  /* font-family: "Source Code Pro", monospace; */
  color: #102a42;
  /* background-color: #dae2ec; */
}

h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
}

h2 {
  font-size: 2rem;
  text-align: center;
  margin: 3rem 0;
}

h3 {
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.125rem;
  line-height: 1.5;
}

section {
  max-width: 800px;
  padding: 1rem 2rem;
  margin: auto;
  margin: 0 auto 3rem auto;
  background-color: #f8f5f1;
}

hr {
  background-color: #243a52;
  border: none;
  height: 1px;
}

.coverLetter {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  color: #243a52;
}

.signing {
  margin-top: 2rem;
  text-align: right;
}

.cv {
  max-width: 800px;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  color: #243a52;
  margin: 0 auto 5rem auto;
}

.header {
  display: flex;
  flex-direction: row;
  grid-column-gap: 1rem;
  margin-bottom: 2rem;
}

.profileImg {
  max-width: 175px;
}

.contact {
  display: flex;
  flex-direction: column;
}

.companySection {
}

.companyDescription {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.experience {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.company {
  font-size: 1.5rem;
}

.languages {
  margin-bottom: 3rem;
}
